.Toastify__toast-container {
  z-index: 9999;
  box-sizing: border-box;
  color: #fff;
  width: 320px;
  padding: 4px;
  position: fixed;
  -webkit-transform: translateZ(9999px);
}

.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}

.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}

.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}

.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (width <= 480px) {
  .Toastify__toast-container {
    width: 100vw;
    margin: 0;
    padding: 0;
    left: 0;
  }

  .Toastify__toast-container--top-center, .Toastify__toast-container--top-left, .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--rtl {
    left: auto;
    right: 0;
  }
}

.Toastify__toast {
  box-sizing: border-box;
  cursor: pointer;
  direction: ltr;
  border-radius: 4px;
  justify-content: space-between;
  min-height: 64px;
  max-height: 800px;
  margin-bottom: 1rem;
  padding: 8px;
  font-family: sans-serif;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1px 10px #0000001a, 0 2px 15px #0000000d;
}

.Toastify__toast--rtl {
  direction: rtl;
}

.Toastify__toast--dark {
  color: #fff;
  background: #121212;
}

.Toastify__toast--default {
  color: #aaa;
  background: #fff;
}

.Toastify__toast--info {
  background: #3498db;
}

.Toastify__toast--success {
  background: #07bc0c;
}

.Toastify__toast--warning {
  background: #f1c40f;
}

.Toastify__toast--error {
  background: #e74c3c;
}

.Toastify__toast-body {
  flex: auto;
  margin: auto 0;
  padding: 6px;
}

.Toastify--animate {
  animation-duration: .7s;
  animation-fill-mode: both;
}

@media only screen and (width <= 480px) {
  .Toastify__toast {
    border-radius: 0;
    margin-bottom: 0;
  }
}

.Toastify__close-button {
  color: #fff;
  cursor: pointer;
  opacity: .7;
  background: none;
  border: none;
  outline: none;
  align-self: flex-start;
  padding: 0;
  transition: all .3s;
}

.Toastify__close-button--default {
  color: #000;
  opacity: .3;
}

.Toastify__close-button > svg {
  fill: currentColor;
  width: 14px;
  height: 16px;
}

.Toastify__close-button:focus, .Toastify__close-button:hover {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }

  to {
    transform: scaleX(0);
  }
}

.Toastify__progress-bar {
  z-index: 9999;
  opacity: .7;
  transform-origin: 0;
  background-color: #ffffffb3;
  width: 100%;
  height: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.Toastify__progress-bar--animated {
  animation: linear forwards Toastify__trackProgress;
}

.Toastify__progress-bar--controlled {
  transition: transform .2s;
}

.Toastify__progress-bar--rtl {
  transform-origin: 100%;
  left: auto;
  right: 0;
}

.Toastify__progress-bar--default {
  background: linear-gradient(90deg, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
}

.Toastify__progress-bar--dark {
  background: #bb86fc;
}

@keyframes Toastify__bounceInRight {
  0%, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInLeft {
  0%, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInUp {
  0%, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  75% {
    transform: translate3d(0, 10px, 0);
  }

  90% {
    transform: translate3d(0, -5px, 0);
  }

  to {
    transform: translateZ(0);
  }
}

@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes Toastify__bounceInDown {
  0%, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.Toastify__bounce-enter--bottom-left, .Toastify__bounce-enter--top-left {
  animation-name: Toastify__bounceInLeft;
}

.Toastify__bounce-enter--bottom-right, .Toastify__bounce-enter--top-right {
  animation-name: Toastify__bounceInRight;
}

.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}

.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--bottom-left, .Toastify__bounce-exit--top-left {
  animation-name: Toastify__bounceOutLeft;
}

.Toastify__bounce-exit--bottom-right, .Toastify__bounce-exit--top-right {
  animation-name: Toastify__bounceOutRight;
}

.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}

.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes Toastify__zoomOut {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotateX(90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotateX(-20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotateX(10deg);
  }

  80% {
    transform: perspective(400px)rotateX(-5deg);
  }

  to {
    transform: perspective(400px);
  }
}

@keyframes Toastify__flipOut {
  0% {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotateX(-20deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotateX(90deg);
  }
}

.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  0% {
    visibility: visible;
    transform: translate3d(110%, 0, 0);
  }

  to {
    transform: translateZ(0);
  }
}

@keyframes Toastify__slideInLeft {
  0% {
    visibility: visible;
    transform: translate3d(-110%, 0, 0);
  }

  to {
    transform: translateZ(0);
  }
}

@keyframes Toastify__slideInUp {
  0% {
    visibility: visible;
    transform: translate3d(0, 110%, 0);
  }

  to {
    transform: translateZ(0);
  }
}

@keyframes Toastify__slideInDown {
  0% {
    visibility: visible;
    transform: translate3d(0, -110%, 0);
  }

  to {
    transform: translateZ(0);
  }
}

@keyframes Toastify__slideOutRight {
  0% {
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}

@keyframes Toastify__slideOutLeft {
  0% {
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}

@keyframes Toastify__slideOutDown {
  0% {
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}

@keyframes Toastify__slideOutUp {
  0% {
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}

.Toastify__slide-enter--bottom-left, .Toastify__slide-enter--top-left {
  animation-name: Toastify__slideInLeft;
}

.Toastify__slide-enter--bottom-right, .Toastify__slide-enter--top-right {
  animation-name: Toastify__slideInRight;
}

.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}

.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--bottom-left, .Toastify__slide-exit--top-left {
  animation-name: Toastify__slideOutLeft;
}

.Toastify__slide-exit--bottom-right, .Toastify__slide-exit--top-right {
  animation-name: Toastify__slideOutRight;
}

.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
}

.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}

.presence-wrapper {
  background-color: #18191c;
  border: 1px solid #383a3f;
  border-radius: 8px;
  width: 268px;
  height: 96px;
  padding: 15px;
}

.presence-wrapper p {
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  margin: 0;
  font-family: Whitney;
  display: block;
  overflow: hidden;
}

.presence-wrapper .type {
  text-transform: uppercase;
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 700;
}

.presence-wrapper .presence {
  display: flex;
}

.presence-wrapper .game {
  font-weight: 600;
}

.presence-wrapper .text {
  margin-left: 10px;
  font-size: 14px;
  line-height: 18px;
  overflow: hidden;
}

.presence-wrapper .images {
  -webkit-user-select: none;
  user-select: none;
  align-self: center;
  width: 60px;
  height: 60px;
  position: relative;
}

.presence-wrapper .large-image {
  border-radius: 8px;
  width: 60px;
}

.presence-wrapper .small-image {
  border: 2px solid #18191c;
  border-radius: 50%;
  width: 20px;
  position: absolute;
  bottom: -4px;
  right: -4px;
}

#hero {
  z-index: -1;
  pointer-events: none;
  width: 100%;
  height: min(100vh, 983px);
  position: absolute;
  top: -10px;
  overflow: hidden;
}

#hero img {
  object-fit: contain;
  min-width: calc(100vw + 10px);
  position: absolute;
  right: -1000px;
}

@media screen and (width <= 768px) {
  #hero img {
    filter: blur(3px);
    width: auto;
    right: -1500px;
  }
}

#title {
  text-align: center;
  text-shadow: 0 0 20px #000, 0 0 40px #000;
  justify-items: center;
  margin: 50px 0;
  display: grid;
}

#title h1 {
  color: #fff;
  margin: 0;
  font-size: 2.4em;
}

#title div a:last-child {
  margin-left: 50px;
}

#sliders {
  grid-auto-flow: row;
  row-gap: 20px;
  padding: 30px 0 50px;
  display: grid;
  overflow: hidden;
}

#sliders > div {
  grid-auto-flow: column;
  column-gap: 60px;
  display: grid;
}

#sliders > div:hover {
  animation-play-state: paused;
}

#sliders .slider-item {
  grid-auto-flow: column;
  column-gap: 60px;
  display: grid;
}

#sliders .animated-row-1 {
  animation: 30s linear infinite animate-row-1;
}

@keyframes animate-row-1 {
  from {
    transform: translateX(-2160px);
  }

  to {
    transform: translateX(0);
  }
}

#sliders .animated-row-3 {
  animation: 40s linear infinite animate-row-3;
}

@keyframes animate-row-3 {
  from {
    transform: translateX(-2160px);
  }

  to {
    transform: translateX(0);
  }
}

#sliders .animated-row-2 {
  animation: 50s linear infinite animate-row-2;
}

@keyframes animate-row-2 {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-2160px);
  }
}

#info-cards {
  background-color: #353535;
  justify-content: center;
  gap: 50px;
  display: flex;
}

@media screen and (width <= 768px) {
  #info-cards {
    flex-direction: column;
    align-items: center;
  }
}

#info-cards .card {
  width: min(25%, 300px);
  overflow: hidden;
}

@media screen and (width <= 768px) {
  #info-cards .card {
    width: 300px;
  }
}

#info-cards .card div:not(.image) {
  padding: 0 5px;
}

#info-cards .image {
  background-color: #18191c;
  border-radius: 5px;
  overflow: hidden;
}

#info-cards .image img {
  height: 150px;
  margin: 0 auto;
  display: block;
}

#info-cards hr {
  border: 1px solid #404040;
  margin: 1em .5em;
}

#info-cards h3 {
  margin: 1rem 0;
}

#dl-decoration {
  width: 300px;
  top: 300px;
  right: 7%;
}

#download-box {
  grid-template: auto 1fr / 2fr 1fr;
  column-gap: 30px;
  display: grid;
}

@media screen and (width <= 768px) {
  #download-box {
    grid-template: none;
    grid-auto-rows: auto;
  }
}

#download-box > div {
  min-width: 0;
}

#download-box > div:first-child {
  word-wrap: break-word;
  grid-row: 1 / 3;
}

#download-box > div:first-child > a {
  margin-top: 0;
  padding: .5em;
  font-size: 1.5em;
}

#download-box > div:last-child {
  border-top: 2px solid gray;
}

#download-box > div:last-child h2 {
  margin: 14px 0;
}

#download-box .button {
  background-color: #447827;
}

ol li {
  padding-left: 20px;
  list-style-type: lower-roman;
}

li::marker {
  color: #bdbdbd;
}

#faq-decoration {
  top: 300px;
  right: 12%;
}

#contents {
  width: fit-content;
}

#contents h2 {
  display: inline;
}

section:not(:first-child) {
  margin-top: 2rem;
}

.transform-flip {
  z-index: 100;
  pointer-events: none;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  transform: scaleX(-1);
}

#cp-decoration-left {
  width: 350px;
  top: 450px;
  right: min(2%, 50px);
}

#cp-decoration-right {
  filter: drop-shadow(-10px 10px 5px #00000080);
  width: 250px;
  top: 180px;
  left: 13%;
}

#cp {
  grid-template: auto auto / repeat(8, 1fr);
  gap: 15px;
  display: grid;
}

@media screen and (width <= 768px) {
  #cp {
    grid-auto-rows: auto;
  }
}

#cp > div:not(#panel-reinstall, #panel-status) {
  padding-top: 0;
}

#cp > #panel-reinstall {
  grid-area: 1 / 1 / 2 / 9;
}

#cp > #panel-status {
  grid-column: 1 / 4;
}

#cp > #panel-settings {
  grid-column: 4 / 9;
}

#cp > #panel-actions, #cp > #panel-links {
  grid-column: 1 / 5;
}

#cp > #panel-tweets {
  flex-direction: column;
  grid-area: span 2 / 5 / auto / 9;
  height: 570px;
  padding: 0;
  display: flex;
}

#cp > #panel-tweets div {
  border: none;
  min-height: 100px;
  overflow: auto;
}

#cp > #panel-tweets h2 {
  padding-left: 30px;
}

#cp > #panel-tweets iframe {
  border-radius: 10px;
}

@media screen and (width <= 768px) {
  #cp {
    margin: 0;
    padding: 0;
  }

  #cp > div {
    grid-column: 1 / 1 !important;
  }
}

#actions {
  grid-template-columns: 1fr 1fr;
  display: grid;
}

h1 {
  margin-top: 0;
}

form h4 {
  margin: 15px 0 10px;
}

label {
  line-height: 1.5em;
  display: inline-block;
}

input[type="text"] {
  color: #fff;
  background-color: #545454;
  border: none;
  border-radius: 3px;
  outline: none;
  padding: 5px;
}

input[type="submit"] {
  font-size: 1em;
}

#orbit-text-container {
  position: relative;
}

#orbit-text-container .presence-wrapper {
  display: none;
  position: absolute;
  top: -50px;
  left: -310px;
}

#orbit-text-container:hover .presence-wrapper, #orbit-text-container:focus-within .presence-wrapper {
  display: block;
}

.info-text {
  color: #999;
  margin: 2px 5px 5px;
  font-size: .8em;
  font-style: italic;
}

.motivational-text {
  color: #d8d8d8;
  font-size: .9em;
}

.__react_component_tooltip {
  max-width: 150px;
}
/*# sourceMappingURL=index.91ef394c.css.map */
