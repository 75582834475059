@import "./mixins.scss";

.transform-flip {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100vh;
    transform: scaleX(-1);
    z-index: 100;
    pointer-events: none;
}

#cp-decoration-left {
    top: 450px;
    right: min(2%, 50px); // flipped, so left is right and right is left
    width: 350px;
}

#cp-decoration-right {
    top: 180px;
    left: 13%;
    width: 250px;
    filter: drop-shadow(rgba(0, 0, 0, 0.5) -10px 10px 5px);
}

#cp {
    // display: grid;
    // grid-template: auto auto / 1fr 1fr;
    // @include isMobile() {
    //     grid-template: none;
    //     grid-auto-flow: row;
    // }
    // gap: 20px;

    // >:nth-child(2) >div {
    //     display: inline-block;
    // }
    display: grid;
    grid-template: auto auto / repeat(8, 1fr);

    @include isMobile() {
        grid-auto-rows: auto;
    }

    gap: 15px;

    >div:not(#panel-reinstall, #panel-status) {
        padding-top: 0;
    }

    >#panel-reinstall {
        grid-row: 1 / 2;
        grid-column: 1 / 9;
    }

    >#panel-status {
        // Control Panel
        // grid-row: 1 / 2;
        grid-column: 1 / 4;
    }

    >#panel-settings {
        // Settings
        grid-column: 4 / 9;
    }

    // >:nth-child(3) { // Hey you...
    //     grid-column: 1 / 4;
    //     p {
    //         margin: 0;
    //     }
    //     span {
    //         color: yellow;
    //     }
    // }
    >#panel-actions {
        // Actions
        grid-column: 1 / 5;
    }

    >#panel-links {
        // Come hang out
        grid-column: 1 / 5;
    }

    >#panel-tweets {
        // Tweets
        grid-row: span 2;
        grid-column: 5 / 9;
        display: flex;
        flex-direction: column;
        height: 570px;
        padding: 0;

        div {
            border: none;
            min-height: 100px;
            overflow: auto;
        }

        h2 {
            padding-left: 30px;
        }

        iframe {
            border-radius: 10px;
        }
    }

    @include isMobile() {
        >div {
            grid-column: 1 / 1 !important;
        }

        padding: 0;
        margin: 0;
    }
}

#actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

h1 {
    margin-top: 0;
}

form h4 {
    margin: 15px 0 10px;
}

label {
    display: inline-block;
    line-height: 1.5em;
}

input {
    &[type=text] {
        padding: 5px;
        background-color: #545454;
        color: white;
        border: none;
        border-radius: 3px;
        outline: none;
    }

    &[type=submit] {
        font-size: 1em;
    }
}

#orbit-text-container {
    position: relative;

    .presence-wrapper {
        position: absolute;
        display: none;
        left: -310px;
        top: -50px;
    }

    &:hover,
    &:focus-within {
        .presence-wrapper {
            display: block;
        }
    }
}

.info-text {
    color: #999999;
    margin: 5px;
    margin-top: 2px;
    font-size: 0.8em;
    font-style: italic;
}

.motivational-text {
    color: #d8d8d8;
    font-size: 0.9em;
}

.__react_component_tooltip {
    max-width: 150px;
}