@import "mixins.scss";
@import "colors.scss";

#dl-decoration {
    top: 300px;
    right: 7%;
    width: 300px;
}

#download-box {
    display: grid;
    grid-template: auto 1fr / 2fr 1fr;
    column-gap: 30px;
    @include isMobile() {
        grid-template: none;
        grid-auto-rows: auto;
    }

    >div {
        min-width: 0;
        &:first-child {
            grid-row: 1 / 3;
            word-wrap: break-word;
            
            // The download button
            >a {
                font-size: 1.5em;
                padding: 0.5em;
                margin-top: 0;
            }
        }
        // History
        &:last-child {
            border-top: 2px solid grey;
            h2 {
                margin: 14px 0;
            }
        }
    }

    .button {
        background-color: #447827;
    }
}

li {
    ol & {
        padding-left: 20px;
        list-style-type: lower-roman;
    }
    &::marker {
        color: #bdbdbd;
    }
}