@import "../colors.scss";

.presence-wrapper {
    width: 300px - 32px;
    height: 128px - 32px;

    background-color: $presence;
    border-radius: 8px;
    border: 1px solid #383a3f;
    padding: 15px;
    
    p {
        display: block;
        margin: 0;
        text-align: left;
        font-family: "Whitney";

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
    }

    .type {
        margin-bottom: 8px;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
    }

    .presence {
        display: flex;
    }
    .game {
        font-weight: 600;
    }
    .text {
        overflow: hidden;
        margin-left: 10px;
        line-height: 18px;
        font-size: 14px;
    }

    .images {
        position: relative;
        align-self: center;
        width: 60px;
        height: 60px;
        user-select: none;
    }
    .large-image {
        width: 60px;
        border-radius: 8px;
    }
    .small-image {
        position: absolute;
        bottom: -4px;
        right: -4px;
        width: 20px;

        border-radius: 50%;
        border: 2px solid $presence;
    }
}