#faq-decoration {
    top: 300px;
    right: 12%;
}

#contents {
    width: fit-content;

    h2 {
        display: inline;
    }
}

section {
    &:not(:first-child) {
        margin-top: 2rem;
    }
}